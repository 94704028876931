@import '../../assets/styles/mixin';

#ranking-wrapper {
  background: url('../../assets/images/background-ranking.jpg') no-repeat center;
  background-size: contain;
  flex: 1;

  .loading {
    text-shadow: 0rem 0.063rem 0.188rem rgba(0, 0, 0, 0.25);
    border-radius: 100rem;
    aspect-ratio: 1/1;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-shadow: 0rem 0.063rem 0.188rem rgba(0, 0, 0, 0.25);
  }

  div.boards {
    div.head {
      div.board-title {
        h4 {
          text-shadow: 0rem 0.063rem 0.188rem rgba(0, 0, 0, 0.25);
        }
      }
    }

    div.list {
      overflow-x: hidden;
      overflow-y: auto;

      .my-position {
        .position,
        .rate {
          font-weight: 700;
          font-size: 1.125rem;
          line-height: 1.313rem;
          text-align: center;

          @include bp-tablet-max {
            font-size: 1rem;
          }

          span {
            font-weight: 400;
            font-size: 0.875rem;
            line-height: 1.15em;
            text-align: center;
            display: block;
            text-transform: uppercase;
            margin-bottom: 0.188rem;

            @include bp-tablet-max {
              font-size: 0.813rem;
            }
          }
        }

        .rate {
          span {
            text-transform: none;
          }
        }

        .user {
          p {
            font-weight: 400;
            font-size: 0.875rem;
            line-height: 1.15em;
            text-align: center;

            @include bp-tablet-max {
              font-size: 0.813rem;
            }
          }
        }
      }

      ul {
        li {
          border-bottom: 0.063rem dashed #888888;
          padding-bottom: 0.5rem;
          margin-bottom: 0.5rem;

          &:last-child {
            margin-bottom: 0;
            padding-bottom: 0;
            border: none;
          }

          > div {
            &.active {
              @apply bg-purple-light;
            }
          }

          .position {
            font-weight: 700;
            font-size: 1rem;
            line-height: 1.25rem;
            text-align: center;
            margin-right: 1.375rem;

            .up,
            .down {
              display: block;
            }

            .up {
              color: #3dd208;
            }

            .down {
              color: #c92703;
            }

            span {
              font-weight: 400;
              font-size: 0.875rem;
              line-height: 1rem;
              text-align: center;
              display: block;
              text-transform: uppercase;
              margin-bottom: 0.188rem;
            }
          }

          .rate {
            font-weight: 700;
            font-size: 0.875rem;
            line-height: 1.125rem;
            display: flex;
            align-items: center;
            text-align: right;

            span {
              font-weight: 400;
              font-size: 0.875rem;
              line-height: 1rem;
              text-align: center;
              display: block;
              margin-bottom: 0.188rem;
            }
          }

          .user {
            p {
              font-weight: 400;
              font-size: 0.875rem;
              line-height: 1.125rem;

              @include bp-tablet-max {
                width: calc(100% - 3.75rem);
              }
            }
          }
        }
      }

      &.has-position {
        ul {
          li {
            .position,
            .rate {
              span {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}
