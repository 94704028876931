@import '../../assets/styles/mixin';

.game-container {
  @include bp-tablet-max {
    aspect-ratio: 16 / 9;
  }

  &.custom-height {
    @include bp-tablet-max {
      height: var(--height, 550px);
    }
  }

  &.aspect-ratio-16-9 {
    aspect-ratio: 16 / 9;
  }
}
