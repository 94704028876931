@import '../../assets/styles/mixin';

.card-course {
  > a {
    &:hover {
      img {
        transform: scale(1.1);
      }
    }
  }

  &__img {
    box-shadow: 0rem 0.25rem 1.5rem rgba(0, 0, 0, 0.1);
    border-radius: 1.25rem;
    width: 10rem;
    height: 9.375rem;

    @include bp-tablet-max {
      width: 7.5rem;
      height: 7.5rem;
      border-radius: 0.75rem;
    }
  }

  &__data {
    width: calc(100% - 11.25rem);

    @include bp-tablet-max {
      width: calc(100% - 8.438rem);
    }

    h3 {
      font-size: 1.75rem;

      @include bp-tablet-max {
        font-size: 1rem;
      }
    }

    .status {
      color: #2bb788;
    }

    .progress {
    }
  }
}
