@import '../../assets/styles/mixin';

.badges {
  .group {
    margin-bottom: 0.938rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
