@import '../../assets/styles/mixin';

.card-lesson {
  > a {
    border-radius: 3rem;
    max-height: 24.375rem;

    @include bp-tablet-max {
      border-radius: 0;
      max-height: none;
    }

    @media only screen and (min-width: 1024px) {
      height: 24.375rem;
    }

    &:before {
      z-index: 1;
      content: ' ';
      background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 41.67%);
      opacity: 0.8;
      display: block;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      position: absolute;

      @include bp-tablet-max {
        border-radius: 0.75rem;
      }
    }

    &:hover {
      img {
        transform: scale(1.1);
      }
    }

    img {
      @include bp-tablet-max {
        margin-bottom: 0.75rem;
        border-radius: 0.75rem;
      }

      @media only screen and (min-width: 1024px) {
        height: 100%;
      }
    }
  }

  $space: 2.25rem;

  &__metadata {
    top: $space;
    left: $space;
    width: calc(100% - (#{$space} * 2));

    @include bp-tablet-max {
      top: 0.938rem;
      left: 0.938rem;
      width: calc(100% - 1.875rem);
    }
  }

  &__crumbs {
    width: 42%;

    @include bp-tablet-max {
      width: 65%;
    }
  }

  &__time {
    @apply bg-orange;
    padding: 0.5rem 0.875rem;

    // @include bp-tablet-max {
    //   position: absolute;
    //   right: 0.938rem;
    //   top: 0.938rem;
    //   z-index: 10;
    // }
  }

  &__title {
    bottom: $space;
    left: $space;
    width: calc(100% - (#{$space} * 2));
    min-height: 5.5rem;
    padding: 1.375rem 1.5rem;

    @include bp-tablet-max {
      width: 100%;
      bottom: 0;
      left: 0;
      min-height: auto;
      padding: 0;
    }

    h3 {
      font-family: 'museo_sans_rounded700';
    }

    p {
      font-family: 'museo_sans_rounded300';
    }
  }
}
