@import '../../assets/styles/mixin';

#modal {
  z-index: 900;
  transition: opacity 0.4s ease-in-out 0s;
  opacity: 1;
  animation-duration: 0.4s;
  animation-name: fadeIn;

  @include bp-desktop-min {
    ::-webkit-scrollbar {
      width: 0.313rem;
      background: transparent;

      &-thumb {
        background: rgba(0, 0, 0, 0.4);
        border-radius: 0.313rem;
      }
    }
  }

  &.fadeOut {
    opacity: 0;
  }

  .bt-close {
    font-size: 1.875rem;
    top: 0.938rem;
    right: 0.938rem;
    color: #fff;
  }

  .modal-container {
    padding: 3.125rem;
    width: 100%;
    height: auto;
    max-height: 100%;
    pointer-events: none;
  }

  .modal-content {
    width: 100%;
    height: auto;
    max-height: calc(100vh - 6.25rem);
    overflow-x: hidden;
    overflow-y: auto;
    pointer-events: initial;
    margin: 0 auto;
  }

  &.video {
    .modal-content {
      max-width: 56.25rem;
    }
  }

  &.text {
    .modal-content {
      max-width: 80rem;
      padding: 1.875rem;
      background: #fff;

      > div {
        width: 100%;
        max-height: calc(100vh - 10rem);
        height: auto;
        overflow-x: hidden;
        overflow-y: auto;
        padding-right: 1.25rem;
      }
    }
  }

  &.quiz {
    .modal-content {
      max-width: 80rem;
      padding: 1.875rem;
      background: #fff;

      > div {
        width: 100%;
        max-height: calc(100vh - 10rem);
        height: auto;
        overflow-x: hidden;
        overflow-y: auto;
        padding-right: 1.25rem;
      }
    }
  }

  &.achievement {
    .modal-content {
      max-width: 37.5rem;
    }
  }

  &[class*='game'] {
  }
}
