@font-face {
  font-family: 'museo_sans_rounded300';
  src: url('../fonts/museosansrounded300.woff2') format('woff2'), url('../fonts/museosansrounded300.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'museo_sans_rounded500';
  src: url('../fonts/museosansrounded500.woff2') format('woff2'), url('../fonts/museosansrounded500.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'museo_sans_rounded700';
  src: url('../fonts/museosansrounded700.woff2') format('woff2'), url('../fonts/museosansrounded700.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
