@import '../../assets/styles/mixin';

#courses-detail {
  div.infos {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include bp-tablet-max {
      display: block;
      width: 100%;
    }

    .img {
      border-radius: 1.563rem;
      height: 15.625rem;
      width: 15.625rem;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      @include bp-tablet-max {
        width: 100%;
        height: auto;
        border-radius: 0.938rem;
        margin-bottom: 0.938rem;
      }

      &:before {
        z-index: 1;
        content: ' ';
        background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 41.67%);
        opacity: 0.8;
        display: block;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        position: absolute;
      }

      img {
        width: 100%;
        height: 100%;
        display: flex;
        object-fit: cover;
      }
    }

    div.data {
      width: calc(100% - 17.5rem);

      @include bp-tablet-max {
        width: 100%;
      }

      h2 {
        font-weight: bold;
        font-size: 1.75rem;
        display: block;
        margin-bottom: 0.125rem;

        @include bp-tablet-max {
          font-size: 1.25rem;
          line-height: 1.1em;
          margin-bottom: 0.313rem;
        }
      }

      .status {
        color: #2bb788;
        display: block;
      }

      .progress {
        display: block;
      }

      .description {
        padding-top: 1.25rem;
      }
    }
  }

  div.content {
    padding-top: 1.875rem;
    margin-top: 2.625rem;
    border-top: 0.063rem solid rgba(0, 0, 0, 0.08);
    width: 100%;

    @include bp-tablet-max {
      padding-top: 1.563rem;
      margin-top: 1.563rem;
    }

    h3 {
      font-weight: 500;
      font-size: 1.375rem;
      display: block;
      margin-bottom: 1.25rem;

      @include bp-tablet-max {
        font-size: 1.25rem;
      }
    }

    > div.row > div.metadata > span.subfolder {
      display: none;
    }

    .row {
      margin-bottom: 1.25rem;
      padding-bottom: 0.938rem;
      position: relative;
      border-bottom: 0.063rem solid rgba(0, 0, 0, 0.08);

      @include bp-tablet-max {
      }

      &:last-child {
        margin-bottom: 0;
        border-bottom: none;
        padding-bottom: 0;
      }

      .metadata {
        &,
        a {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          width: 100%;
        }

        a {
          .img {
            background: rgba(251, 109, 58, 0.25);

            svg {
              @apply text-orange;
            }
          }
        }

        &.locked {
          cursor: not-allowed;

          .img {
            background: rgba(0, 0, 0, 0.25);

            svg {
              color: #f8f8f8;
            }
          }
        }

        .subfolder {
          margin-right: 0.625rem;
          margin-left: -1.75rem;

          @include bp-tablet-max {
            margin-left: -1.25rem;
          }

          svg {
            font-size: 1.125rem;

            @include bp-tablet-max {
              font-size: 1rem;
            }
          }
        }

        .img {
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 3.438rem;
          height: 3.438rem;
          position: relative;
          background: rgba(80, 62, 157, 0.25);
          border-radius: 1rem;
          margin-right: 1.25rem;

          @include bp-tablet-max {
            width: 2.5rem;
            height: 2.5rem;
            border-radius: 0.625rem;
            margin-right: 0.625rem;
          }

          svg {
            color: #503e9d;
            position: absolute;
            font-size: 1.563rem;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            z-index: 10;

            @include bp-tablet-max {
              font-size: 0.938rem;
            }
          }
        }

        .title {
          font-weight: 500;
          font-size: 1rem;
          line-height: 1em;
          color: #181b1a;
          display: block;
          margin: 0;
          @include trans;
          width: calc(100% - 6.563rem);

          @include bp-tablet-max {
            font-size: 0.938rem;
            width: calc(100% - 3.75rem);
          }

          strong {
            font-weight: 500;
            font-size: 0.875rem;
            line-height: 1.5em;
            color: #b3b6b6;
            display: block;
            margin-top: 0.125rem;

            @include bp-tablet-max {
              font-size: 0.813rem;
            }

            span {
              &.concluded {
                color: #2bb788;
              }
            }
          }
        }
      }

      .children {
        padding-left: 2.813rem;
        display: none;
        margin-bottom: 1.25rem;

        margin-top: 1.25rem;
        padding-top: 0.938rem;
        position: relative;
        border-top: 0.063rem solid rgba(0, 0, 0, 0.08);

        @include bp-tablet-max {
          padding-left: 1.25rem;
        }

        &:last-child {
          margin-bottom: 0;
        }

        &.active {
          display: block;
        }
      }
    }
  }
}
