@import '../../assets/styles/mixin';

#register {
  form {
    ul {
      &,
      li {
        width: 100%;
        list-style: none;
        padding: 0;
      }

      li {
        margin-bottom: 1.25rem;

        &:last-child {
          margin-bottom: 0;
        }

        label {
          @apply block text-sm mb-1 font-medium leading-none;
        }

        select,
        input:not([type='checkbox']),
        textarea {
          width: 100%;
          @apply px-4 py-2 text-sm leading-none border block border-gray-300 rounded;
          height: 50px;
          background: #fff;
          border-radius: 70px;

          &[aria-invalid='true'] {
            border-color: #ff7171;
          }

          @include trans;
          opacity: 1;
          font-size: 1.3em;

          &[disabled] {
            opacity: 0.5;
            cursor: not-allowed;
          }
        }

        textarea {
          height: auto;
          min-height: 9.375rem;
          max-height: 31.25rem;
        }

        span[role='alert'] {
          font-style: italic;
          font-size: 0.75rem;
          color: #ff7171;
          display: block;
        }

        .count {
          font-size: 0.813rem;
          font-style: italic;
          position: absolute;
          right: 0;
          top: 100%;
          display: block;
          text-align: right;
          pointer-events: none;

          @include bp-tablet-max {
            font-size: 0.75rem;
          }
        }
      }
    }

    button[type='submit'] {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 6.25rem;
      height: 2.5rem;
      background: transparent;
      border-radius: 100rem;
      color: #fff;
      font-size: 1rem;
      font-weight: 500;
      text-transform: uppercase;
      border: 0.063rem solid #2475ff;
      margin: 0.875rem auto 0;
      @include trans;
      opacity: 1;
      width: 100%;
      height: 53px;
      background-color: #2475ff;

      @include bp-tablet-max {
        font-size: 0.938rem;
      }

      &:hover {
        opacity: 0.75;
      }

      &[disabled] {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }
}

div.container {
  &.register {
    &.no-aside {
      &.aside-min {
        width: 50%;
        margin: 0 auto;
      }
    }
  }
}

@media (max-width: 768px) {
  div.container {
    &.register {
      &.no-aside {
        &.aside-min {
          width: 100%;
          margin: 0 auto;
        }
      }
    }
  }
}
