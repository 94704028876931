@import '../../assets/styles/mixin';

#news {
  // $color-border: #e7e7e7;
  $color-border: transparent;

  h2 {
    span {
      @apply text-orange;
    }
  }

  // .container {

  //   border-radius: 0.75rem;
  //   border: 0.063rem solid $color-border;
  //   border-top: none;
  //   padding: 1rem;
  //   border-radius: 0 0 0.75rem 0.75rem;
  // }

  .item-news {
    position: relative;
    margin: 0 0 1rem;
    @apply bg-gray-50 shadow-md;
    border-radius: 0.625rem;
    transition: all 0.25s ease-in-out;

    &.opened {
      box-shadow: 0rem 0.25rem 1.5rem rgba(0, 0, 0, 0.1);

      .btn {
        span.arrow {
          transform: rotate(0deg);
        }
      }

      .content {
        padding: 0.625rem 0.938rem;
        max-height: none;
        opacity: 1;
        transform: translate(0, 0);

        // @include bp-tablet-max {
        //   padding: 1.125rem;
        // }

        @include bp-tablet-max {
          padding: 0.813rem;
        }
      }
    }

    &.new {
      @apply bg-orange-opacity-30;

      .btn {
        .ico {
          @apply bg-orange;
        }

        .infos .text {
          font-weight: 500;
        }
      }
    }

    .btn {
      text-align: left;
      padding: 0.625rem 0.938rem;

      @include bp-tablet-max {
        padding: 0.625rem 0.813rem;
      }

      .ico {
        margin-right: 0.625rem;
        width: 0.375rem;
        height: 0.375rem;

        @include bp-tablet-max {
          margin-right: 0.375rem;
        }
      }

      .image {
        width: 3.75rem;
        height: 3.75rem;
        margin-right: 0.938rem;

        @include bp-tablet-max {
          width: 2.813rem;
          height: 2.813rem;
          margin-right: 0.75rem;
        }

        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .infos {
        width: calc(100% - 7.75rem); // 100% - width of ico, image and arrow
        margin-right: 0.938rem;

        @include bp-tablet-max {
          width: calc(100% - 5.938rem);
          margin-right: 0.75rem;
        }

        .text {
          font-size: 1rem;
          line-height: 1.15em;

          @include bp-tablet-max {
            font-size: 0.875rem;
          }
        }

        .date {
          font-size: 0.75rem;
          line-height: 1em;
          font-style: italic;
          display: block;
          margin-top: 0.125rem;
        }
      }

      span.arrow {
        font-size: 1.125rem;
        transition: all 0.25s ease-in-out;
        transform: rotate(180deg);

        @include bp-tablet-max {
          font-size: 1rem;
        }
      }
    }

    div.content {
      color: rgba(0, 0, 0, 0.7);
      font-size: 0.875rem;
      line-height: 1.25em;
      position: relative;
      overflow: hidden;
      z-index: 2;
      max-height: 0;
      opacity: 0;
      transform: translate(0, 50%);
      transition: all 0.25s ease-in-out;
      width: 100%;
      padding: 0 0.938rem;

      @include bp-tablet-max {
        padding: 0 0.813rem;
      }

      * {
        line-height: inherit;
      }

      h1 {
        font-size: 1.25rem;
        font-weight: bold;
      }

      h2 {
        font-size: 1.125rem;
        font-weight: bold;
      }

      h3 {
        font-size: 1rem;
        font-weight: bold;
      }

      h4,
      h5,
      h6 {
        font-size: 0.875rem;
        font-weight: bold;
      }

      p {
      }

      ul {
        list-style: initial;
        display: block;
        list-style-type: disc;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        padding-inline-start: 2.5rem;
      }

      ol {
        display: block;
        list-style: initial;
        list-style-type: decimal;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        padding-inline-start: 2.5rem;
      }

      blockquote {
        $border-radius: 0.5rem;

        position: relative;
        margin: 2.5rem 0;
        padding: 2.188rem 1.563rem 2.188rem;
        font: italic 1.2rem 'Vollkorn', 'Open Sans', Helvetica, Arial, sans-serif;
        border-radius: $border-radius;
        border: 0.125rem solid #fff;
        box-shadow: 0.125rem 0.125rem 0.25rem rgba(0, 0, 0, 0.226);
        text-indent: 1.25em;

        @media (min-width: 768px) {
          margin: 2.5rem 3.75rem;
        }

        &:before {
          content: '';
          pointer-events: none;
          position: absolute;
          z-index: 1;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          border-radius: $border-radius;
          box-shadow: inset -0.125rem -0.125rem 0.063rem rgb(255, 255, 255), inset 0.125rem 0.125rem 0.25rem rgba(0, 0, 0, 0.2);
        }

        &:after {
          content: '❝';
          position: absolute;
          z-index: 1;
          left: 50%;
          top: -1.25rem;
          transform: translateX(-50%);
          width: 2.5rem;
          height: 2.5rem;
          background: #fff;
          box-shadow: 0 0.25rem 0.313rem -0.063rem rgba(0, 0, 0, 0.2);
          border-radius: 999rem;
          display: flex;
          align-items: center;
          justify-content: center;
          @apply text-orange;
          font-size: 2.188rem;
          line-height: 1em;
          font-style: normal;
          text-indent: 0;
          padding-top: 0.875rem;
        }
      }

      code,
      kbd,
      samp,
      pre {
        display: block;
        font-family: monospace;
        margin: 1em 0px;
        color: #f8f8f2;
        font-size: 0.875rem;
        background: #282a35;
        padding: 0.625rem;
        width: 100%;
        overflow-x: auto;
        white-space: pre-wrap;

        * {
          color: inherit !important;
          font-size: inherit !important;
          font-family: inherit !important;
        }
      }

      a {
        text-decoration: underline;
        @apply text-orange;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}
