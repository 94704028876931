@tailwind base;
@tailwind components;
@tailwind utilities;

@import './mixin';
@import 'fonts';

html,
body {
  min-width: 100vw;
  min-height: 100vh;
}

html {
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  font-size: 16px;

  @include bp-lg-xl {
    font-size: 13px;
  }

  @include bp-xl-2xl {
    font-size: 14px;
  }
}

body {
  @apply p-safe text-white antialiased select-none font-sans;
  background-blend-mode: overlay, normal, normal;
  overflow-y: auto;
  overflow-x: hidden;
  font-size: 0.875rem;
  background: var(--bg-page);

  @include bp-tablet-max {
    font-size: 0.75rem;
  }

  /* PWA enhancements */
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;

  // p,
  // h1,
  // h2,
  // h3,
  // h4,
  // h5,
  // h6,
  // span,
  // strong,
  // li {
  //   user-select: text;
  // }

  ::-webkit-input-placeholder {
    opacity: 1;
    -webkit-transition: opacity 0.2s;
    transition: opacity 0.2s;
  }

  :-moz-placeholder {
    opacity: 1;
    -moz-transition: opacity 0.2s;
    transition: opacity 0.2s;
  }

  ::-moz-placeholder {
    opacity: 1;
    -moz-transition: opacity 0.2s;
    transition: opacity 0.2s;
  }

  :-ms-input-placeholder {
    opacity: 1;
    -ms-transition: opacity 0.2s;
    transition: opacity 0.2s;
  }

  ::placeholder {
    opacity: 1;
    transition: opacity 0.2s;
  }

  *:focus::-webkit-input-placeholder {
    opacity: 0;
  }

  *:focus:-moz-placeholder {
    opacity: 0;
  }

  *:focus::-moz-placeholder {
    opacity: 0;
  }

  *:focus:-ms-input-placeholder {
    opacity: 0;
  }

  *:focus::placeholder {
    opacity: 0;
  }
}

@include bp-desktop-min {
  ::-webkit-scrollbar {
    width: 5px;
    background: #0f1b37;

    &-thumb {
      background: rgba(255, 255, 255, 0.8);
      border-radius: 5px;
    }
  }
}

body.modal-open,
body.menu-open {
  overflow: hidden;
}

div.container {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  padding: 24px;
  max-width: 1920px;
  min-width: auto;
  margin: 0 auto;
  width: 100%;

  @include bp-tablet-max {
    padding: 0 15px 15px;
    display: block;
  }

  @include bp-lg-xl {
    padding: 15px;
  }

  @include bp-xl-2xl {
    padding: 20px;
  }

  aside {
    width: 336px;
    min-height: calc(100vh - 48px);
    @include trans;

    @include bp-tablet-max {
      width: 100%;
      min-height: auto;
    }

    @include bp-lg-xl {
      width: 260px;
      min-height: calc(100vh - 30px);
    }

    @include bp-xl-2xl {
      width: 290px;
      min-height: calc(100vh - 40px);
    }
  }

  main {
    width: calc(100% - 336px);
    background: #ffffff;
    border-radius: 48px;
    min-height: calc(100vh - 48px);
    @apply text-black-light;
    @include trans;
    padding: 42px 38px;

    @include bp-tablet-max {
      padding: 15px;
      margin: 0;
      min-height: calc(100vh - 108px);
      border-radius: 22px;
      width: 100%;
    }

    @include bp-lg-xl {
      width: calc(100% - 260px);
      border-radius: 20px;
      min-height: calc(100vh - 30px);
      padding: 15px;
    }

    @include bp-xl-2xl {
      width: calc(100% - 290px);
      border-radius: 30px;
      min-height: calc(100vh - 40px);
      padding: 20px;
    }

    @include bp-desktop-min {
      ::-webkit-scrollbar {
        width: 5px;
        background: rgba(0, 0, 0, 0.1);

        &-thumb {
          background: #503e9d;
          border-radius: 5px;
        }
      }
    }
  }

  &.aside-min {
    aside {
      width: 180px;

      @include bp-tablet-max {
        width: 100%;
      }

      @include bp-lg-xl {
        width: 130px;
      }

      @include bp-xl-2xl {
        width: 150px;
      }
    }

    main {
      width: calc(100% - 180px);

      @include bp-tablet-max {
        width: 100%;
      }

      @include bp-lg-xl {
        width: calc(100% - 130px);
      }

      @include bp-xl-2xl {
        width: calc(100% - 150px);
      }
    }
  }

  &.no-aside {
    main {
      width: 100%;
    }
  }

  &.auth {
    main {
      background: transparent;
    }
  }
}

#user-info {
  .user-gamification {
    @include bp-tablet-max {
      margin: 0;
      display: flex;
      align-items: center;
      flex-direction: column;

      .username {
        &:before {
          content: 'Olá, ';
        }

        &:after {
          content: '!';
        }
      }
    }
  }
}

html body .Toastify__toast-container {
  z-index: 1000;
}

@keyframes like-button-animation {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.2);
  }
  50% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

img[alt*='XP'],
img[alt*='xp'],
img[alt*='Coins'],
img[alt*='coins'] {
  border-radius: 9999rem;
}
