@import '../../assets/styles/mixin';

#terms {
  .content {
    color: rgba(0, 0, 0, 0.7);
    font-size: 0.938rem;
    line-height: 1.3em;

    @include bp-tablet-max {
      font-size: 0.813rem;
    }
  }

  .accept {
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border-radius: 100rem;
    color: #3f5b9d;
    font-size: 1rem;
    font-weight: 500;
    text-transform: uppercase;
    border: 0.063rem solid #3f5b9d;
    margin: 1.875rem auto 0;
    transition: all 0.25s ease 0s;
    opacity: 1;
    padding: 15px;
  }
}
