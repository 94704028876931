@import '../../assets/styles/mixin';

.user-stats {
  div.item {
    p {
      font-size: 1rem;
      font-weight: 500;

      @include bp-tablet-max {
      }

      strong {
        display: block;
        font-size: 0.875rem;
        font-weight: normal;

        @include bp-tablet-max {
        }
      }
    }
  }

  div.points {
    width: calc(50% - 1.875rem);

    @include bp-tablet-max {
      width: 100%;
    }

    div.item {
      margin-bottom: 0.938rem;

      @include bp-tablet-max {
      }

      &:last-child {
        margin-bottom: 0;
      }

      .img {
        width: 3.125rem;
        height: 3.125rem;
        margin-right: 0.625rem;

        @include bp-tablet-max {
          width: 2.5rem;
          height: 2.5rem;
        }

        img,
        svg {
          object-fit: contain;
          @apply text-purple;
          font-size: 3.125rem;

          @include bp-tablet-max {
            font-size: 2.5rem;
          }
        }
      }

      p {
        font-size: 1.25rem;

        @include bp-tablet-max {
        }

        strong {
          margin-bottom: -0.25rem;

          @include bp-tablet-max {
          }
        }
      }
    }
  }

  div.levels {
    width: calc(50% + 1.875rem);
    padding-left: 1.875rem;

    @include bp-tablet-max {
      width: 100%;
      padding: 1.25rem 0 0.938rem;
      margin-top: 1.25rem;
    }

    div.item {
      margin-bottom: 0.938rem;

      @include bp-tablet-max {
      }

      &:last-child {
        margin-bottom: 0;
      }

      p {
        font-size: 1.25rem;

        @include bp-tablet-max {
        }

        strong {
          margin-bottom: -0.25rem;
          @apply text-black-light;

          @include bp-tablet-max {
          }
        }
      }

      .actual {
        color: #2bb788;
      }

      .next {
        @apply text-orange;
      }
    }
  }

  .progress {
    position: relative;
    margin-top: 0.375rem;

    @include bp-tablet-max {
    }

    .actual,
    .next {
      position: absolute;
      top: calc(100% + 0.125rem);
      font-size: 0.813rem;
      line-height: 1em;
      font-weight: normal;

      @include bp-tablet-max {
      }
    }

    .actual {
      left: 0;
    }

    .next {
      right: 0;
    }

    .bar {
      span {
        @apply bg-orange;
        color: #fff;
        font-size: 0.75rem;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 1em;
        min-width: 2.5rem;

        @include bp-tablet-max {
        }
      }
    }
  }
}
