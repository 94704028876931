@import '../../assets/styles/mixin';

#messages {
  // $color-border: #e7e7e7;
  $color-border: transparent;

  h2 {
  }

  .tabs {
    &-container {
      box-shadow: 0rem 0.25rem 1.5rem rgba(0, 0, 0, 0.1);
      border-radius: 0.75rem;

      @include bp-tablet-max {
      }
    }

    &-head {
      @include bp-tablet-max {
        overflow-y: hidden;
        overflow-x: auto;

        border-radius: 0.75rem 0.75rem 0 0;
      }

      ul {
        @include bp-tablet-max {
          flex-wrap: nowrap;
        }

        li {
          width: 50%;

          @include bp-tablet-max {
            width: auto;
          }

          button {
            border: none;
            background: none;
            padding: 0;
            margin: 0;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            width: 100%;
            background: #efefef;
            border: 0.063rem solid $color-border;
            @apply text-base lg:text-xl;
            padding: 0.75rem;

            @include bp-tablet-max {
              white-space: nowrap;
            }

            span {
              @apply text-orange;
              margin-left: 0.5rem;
            }
          }

          &.active {
            button {
              cursor: default;
              @apply bg-gray-50 border-gray-50;
              border-top-color: $color-border;
              border-left-color: $color-border;
              border-right-color: $color-border;
            }
          }

          &:nth-child(1) {
            button {
              border-right: none;
              border-radius: 0.75rem 0 0 0;
            }
          }

          &:nth-child(2) {
            button {
              border-right: none;
            }
          }

          &:nth-child(3) {
            button {
              border-radius: 0 0.75rem 0 0;
            }
          }
        }
      }
    }

    &-content {
      @apply bg-gray-50;
      border: 0.063rem solid $color-border;
      border-top: none;
      padding: 1rem;
      border-radius: 0 0 0.75rem 0.75rem;

      @include bp-tablet-max {
        padding: 0.625rem;
      }
    }
  }

  .item-notification {
    padding: 0.625rem 0.938rem;
    margin: 0.625rem 0;
    background: #fff;
    border-radius: 0.625rem;

    @include bp-tablet-max {
      flex-wrap: wrap;
      padding: 0.625rem;
    }

    .ico {
      margin-right: 0.625rem;
      width: 0.375rem;
      height: 0.375rem;

      @include bp-tablet-max {
        margin-right: 0.375rem;
      }
    }

    &.new {
      @apply bg-orange-opacity-30;

      .ico {
        @apply bg-orange;
      }

      .infos .text {
        font-weight: 500;
      }
    }

    .image {
      width: 3.75rem;
      height: 3.75rem;
      margin-right: 0.938rem;

      @include bp-tablet-max {
        width: 2.813rem;
        height: 2.813rem;
        margin-right: 0.75rem;
      }

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .infos {
      width: calc(100% - 5.688rem); // 100% - width of ico and image
      flex: 1 1;

      @include bp-tablet-max {
        width: auto;
      }

      .text {
        font-size: 1rem;
        line-height: 1.15em;

        @include bp-tablet-max {
          font-size: 0.875rem;
        }
      }

      .date {
        font-size: 0.75rem;
        line-height: 1em;
        font-style: italic;
        display: block;
        margin-top: 0.125rem;
      }
    }

    .actions {
      @include bp-tablet-max {
        margin-top: 1.25rem;
      }

      > * {
        font-size: 1.25rem;
        line-height: 1em;
        background: none;
        padding: 0;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        &[disabled] {
          opacity: 0.6;
          color: #2d2d31 !important;
        }

        @include bp-tablet-max {
          font-size: 1rem;
        }
      }

      .mark-read {
        color: green;
        margin-right: 0.938rem;

        @include bp-tablet-max {
          margin-right: 0.75rem;
        }
      }

      .trash {
        color: #b13d3d;
      }

      .alert {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        white-space: nowrap;
        font-size: 0.75rem;
        @apply text-black-light;
        font-style: italic;
        line-height: 1em;

        .buttons {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 1.563rem;
          margin: 0.375rem 0px 0;

          @include bp-tablet-max {
            font-size: 1.25rem;
          }

          > * {
            margin: 0 0.313rem;
            display: block;
          }

          .no {
            color: green;
          }

          .yes {
            color: #b13d3d;
          }
        }
      }
    }

    &.private {
      .infos {
        margin-right: 0.938rem;
        width: calc(100% - 10.063rem); // 100% - width of ico, image and actions - spacing
      }

      @include bp-tablet-max {
        flex-wrap: wrap;
        justify-content: center;

        .infos {
          margin-right: 0;
          width: calc(100% - 4.313rem);
          flex: none;
        }
      }

      &.alert {
        .infos {
          width: calc(100% - 14.063rem); // 100% - width of ico, image and actions - spacing

          @include bp-tablet-max {
            width: calc(100% - 4.313rem);
          }
        }
      }
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }

    .loading {
      animation: spin 1s infinite linear;
    }
  }
}
