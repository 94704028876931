@import '../../assets/styles/mixin';

#games-detail {
  div.head {
    .back {
      border: none;
      background: none;
      outline: none;
      display: flex;
      align-items: center;
      @include trans;
      opacity: 1;

      &:hover {
        opacity: 0.75;
      }

      font-family: 'Poppins';
      font-weight: 500;
      font-size: 1.125rem;
      line-height: 1em;
      color: #181b1a;

      @include bp-tablet-max {
        font-size: 0.938rem;
        margin-bottom: 0.938rem;
      }

      .ico {
        width: 3.5rem;
        height: 3.5rem;
        @apply bg-orange;
        border-radius: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 1rem;

        @include bp-tablet-max {
          width: 2.5rem;
          height: 2.5rem;
          border-radius: 0.75rem;
          margin-right: 0.75rem;
        }

        svg {
          font-size: 1.563rem;
          color: #f8f8f8;

          @include bp-tablet-max {
            font-size: 1.125rem;
          }
        }
      }
    }

    .crumbs {
      &.has-btn-back {
        width: calc(100% - 10rem);

        @include bp-tablet-max {
          width: 100%;
          text-align: left;
        }
      }

      p {
        &,
        * {
          font-family: 'Poppins';
          display: inline;
          font-weight: 700;
          font-size: 1.25rem;
          line-height: 1.3em;
          color: #503e9d;
          margin-bottom: 0.375rem;

          @include bp-tablet-max {
            font-size: 1rem;
            line-height: 1.15em;
            margin-bottom: 0.25rem;
          }
        }

        span {
          &.title {
            font-weight: 500;
            font-size: 1.75rem;
            line-height: 1.5em;
            display: block;
            color: #181b1a;

            @include bp-tablet-max {
              font-size: 1rem;
              line-height: 1.15em;
            }
          }
        }
      }

      .title {
        width: auto;
      }
    }
  }
}
