@import '../../assets/styles/mixin';

.recent-achievements {
  .scroll-wrapper {
    max-height: 25rem;
    padding-right: 1.25rem;

    > div {
      &:last-child {
        border: none;
      }
    }
  }
}
