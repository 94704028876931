@import '../../assets/styles/mixin';

.box-courses {
  @include bp-tablet-max {
  }

  &__head {
    @include bp-tablet-max {
    }

    h2 {
      font-size: 1.75rem;
      line-height: 1.5em;
      color: #181b1a;

      @include bp-tablet-max {
        font-size: 1.25rem;
      }

      span {
        font: inherit;
        line-height: inherit;
        @apply text-orange;

        @include bp-tablet-max {
        }
      }
    }
  }

  &__actions {
    @include bp-tablet-max {
    }

    button {
      color: #dfdfe3;
      font-size: 1.875rem;

      @include bp-tablet-max {
        font-size: 1.25rem;
      }
    }
  }
}
