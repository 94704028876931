@import '../../assets/styles/mixin';

div.achievement-component {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;

  .title {
    color: #6fd181;
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 1.875rem;
    line-height: 1em;
    margin-bottom: 1.563rem;
  }

  .subtitle {
    color: #fff;
    font-family: 'Poppins';
    font-weight: normal;
    font-size: 1.25rem;
    line-height: 1em;
    margin-bottom: 0;
    text-align: center;

    span {
      color: #fccebd;
      display: block;
      margin-top: 0.938rem;
      font-size: 1.5rem;
      font: inherit;
      font-weight: 500;
    }
  }

  .description {
    color: #fff;
    font-family: 'Poppins';
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 1em;
    margin: 1rem 0 0;
    text-align: center;
    display: block;
  }

  .badge {
    width: 12.5rem;

    svg {
      width: 100%;
      display: block;
      margin: 0;
      padding: 0;
    }
  }

  .actions {
    button {
      border: 0.063rem solid #fff;
      border-radius: 100rem;
      background: none;

      font-family: 'Poppins';
      font-style: normal;
      line-height: 1em;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      color: #fff;

      font-size: 0.875rem;
      font-weight: normal;
      text-transform: uppercase;
      padding: 0.5rem 0.938rem;
      @include trans;
      opacity: 1;

      &:hover {
        opacity: 0.75;
      }
    }
  }
}
