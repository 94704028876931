@import '../../assets/styles/mixin';

div.video-component {
  position: relative;
  width: 100%;
  padding-top: 56.25%; // 16:9

  > div {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
}
