@import '../../assets/styles/mixin';

#faq {
  width: 100%;

  .item-faq {
    position: relative;
    margin-bottom: 1.25rem;
    @apply bg-white rounded-xl border;

    @include bp-tablet-max {
      margin-bottom: 1.125rem;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &.opened {
      @apply shadow-md;

      span.arrow {
        transform: rotate(0deg);
      }

      .content {
        padding: 1.25rem;
        max-height: 50rem;
        opacity: 1;
        transform: translate(0, 0);

        @include bp-tablet-max {
          padding: 1.125rem;
          max-height: 37.5rem;
        }
      }
    }

    button {
      padding: 0.938rem 1.25rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      text-align: left;
      @apply text-purple;

      @include bp-tablet-max {
        padding: 0.938rem 1.125rem;
      }

      span.question {
        font-weight: 500;
        font-size: 1.125rem;
        line-height: 1em;
        display: block;
        margin: 0;
        width: calc(100% - 2.5rem);

        @include bp-tablet-max {
          font-size: 1rem;
        }
      }

      span.arrow {
        font-size: 1.125rem;
        transition: all 0.25s ease-in-out;
        transform: rotate(180deg);

        @include bp-tablet-max {
        }
      }
    }

    div.content {
      color: rgba(0, 0, 0, 0.7);
      font-size: 0.938rem;
      line-height: 1.3em;
      position: relative;
      overflow: hidden;
      z-index: 2;
      max-height: 0;
      opacity: 0;
      transform: translate(0, 50%);
      transition: all 0.25s ease-in-out;
      width: 100%;
      padding: 0 1.25rem;

      @include bp-tablet-max {
        font-size: 0.813rem;
        padding: 0 1.125rem;
      }
    }
  }
}
