@import 'assets/styles/mixin';

aside {
  padding-top: 2.313rem;

  @include bp-tablet-max {
    padding: 1.563rem 0;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 5.813rem;
    z-index: 40;
  }

  .bt-menu {
    width: 1.875rem;
    height: 1.438rem;
    transition-duration: 0.5s;
    display: none;
    position: relative;
    z-index: 20;

    @include bp-tablet-max {
      display: block;
    }

    $line-height: 0.188rem;
    $line-width: 1.875rem;
    $line-top: 0.625rem;
    $line-bg: var(--color-menu-idle);

    span {
      transition-duration: 0.5s;
      position: absolute;
      height: $line-height;
      width: $line-width;
      top: $line-top;
      background-color: $line-bg;
      left: 0;

      &:before {
        transition-duration: 0.5s;
        position: absolute;
        height: $line-height;
        width: $line-width;
        background-color: $line-bg;
        content: '';
        top: -#{$line-top};
        left: 0;
      }

      &:after {
        transition-duration: 0.5s;
        position: absolute;
        height: $line-height;
        width: $line-width;
        background-color: $line-bg;
        content: '';
        top: $line-top;
        left: 0;
      }
    }
  }

  &.menu-open {
    .bt-menu {
      span {
        transition-duration: 0.5s;
        background: transparent;

        &:before {
          transform: rotateZ(45deg) scaleX(1.25) translate(0.406rem, 0.406rem);
        }

        &:after {
          transform: rotateZ(-45deg) scaleX(1.25) translate(0.375rem, -0.375rem);
        }
      }
    }

    nav {
      @include bp-tablet-max {
        right: 0;
      }
    }
  }

  h1 {
    width: calc(100% - 6.25rem);
    display: block;
    margin: 0 0 0 2.375rem;
    @include trans;

    @include bp-tablet-max {
      width: auto;
      margin: 0;
      flex: 1;
      padding: 0 1.25rem;
      position: relative;
      z-index: 20;

      a {
        max-width: 7.188rem;
        display: block;
      }
    }
  }

  .partner {
    width: calc(100% - 6.25rem);
    display: block;
    margin: 1.625rem 0 0 2.375rem;
    @include trans;

    @include bp-tablet-max {
      width: 7.5rem;
      margin: 0;
      position: relative;
      z-index: 20;
    }
  }

  nav {
    $height: 3.75rem;

    position: relative;
    padding-bottom: 4.063rem;
    margin-top: 2.5rem;
    @include trans;

    @include bp-tablet-max {
      position: fixed;
      right: 100%;
      display: block;
      background: var(--bg-page);
      width: 100vw;
      height: 100vh;
      padding: 6.25rem 1.563rem 1.563rem;
      margin: 0;
      z-index: 10;
      bottom: 0;
      overflow: hidden;
    }

    .bg-nav {
      $background: #fff;

      @include trans;
      display: block;
      position: absolute;
      right: 0;
      top: calc((#{$height} + 0.313rem) * var(--nav-active-index));
      height: $height;
      background: $background;
      border-radius: $height 0 0 $height;
      width: calc(100% - 0.875rem);
      pointer-events: none;
      user-select: none;
      z-index: -1;

      @include bp-tablet-max {
        display: none;
      }

      span {
        height: calc(#{$height} / 2);
        right: 0;
        width: 100%;
        display: block;
        position: absolute;

        &:before,
        &:after {
          content: ' ';
          display: block;
          position: absolute;
          top: 0;
          right: 0;
          height: 100%;
        }

        &:before {
          background: var(--bg-page);
          width: calc(#{$height} * 2);
          z-index: 2;
        }

        &:after {
          background: $background;
          width: $height;
          z-index: 1;
        }

        &:first-child {
          bottom: 100%;

          &:before {
            border-radius: 0 0 $height 0;
          }
        }

        &:last-child {
          top: 100%;

          &:before {
            border-radius: 0 $height 0 0;
          }
        }
      }
    }

    &.hide-bg-nav {
      .bg-nav {
        opacity: 0;
      }
    }

    ul {
      padding: 0 3.875rem 0 2.375rem;
      margin: 0;
      list-style: none;
      width: 100%;
      position: relative;
      z-index: 10;
      @include trans;

      @include bp-tablet-max {
        padding: 0;
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;
      }

      li {
        padding: 0;
        margin: 0.313rem 0;
        list-style: none;
        display: flex;
        align-items: center;
        min-height: $height;
        width: 100%;
        @include trans;

        @include bp-tablet-max {
          margin: 0.625rem 0;
          min-height: auto;
        }

        &.user {
          @include bp-tablet-max {
            margin: 0 0 1.875rem;

            #user-info {
              display: block;
              width: 100%;
              color: var(--color-menu-idle);
            }
          }
        }

        a {
          display: flex;
          align-items: center;
          color: var(--color-menu-idle);

          font-family: 'Poppins';
          font-style: normal;
          font-weight: 500;
          font-size: 1.125rem;
          line-height: 1em;
          white-space: nowrap;
          opacity: 1;

          @include bp-tablet-max {
            font-size: 1rem;
            padding: 1rem 1.25rem;
            width: 100%;
          }

          &:hover {
            svg {
              opacity: 1;
            }
          }

          svg {
            width: 1.5rem;
            height: auto;
            margin: 0 1.5rem 0 0;
            @include trans;
            color: var(--color-menu-idle);
            opacity: 0.35;

            @include bp-tablet-max {
              width: 1.25rem;
              margin-right: 1.25rem;
            }
          }

          span {
            @include trans;
            color: var(--color-menu-idle);
          }

          &.active {
            &,
            svg,
            span,
            &:hover {
              color: var(--color-menu-active);
              opacity: 1;
            }

            @include bp-tablet-max {
              background: #fff;
              border-radius: 100rem;
            }
          }

          .number {
            min-width: 1.563rem;
            height: 1.563rem;
            background: var(--color-menu-active);
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border-radius: 999em;

            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 0.688rem;
            line-height: 1em;
            color: var(--color-menu-idle) !important;

            margin-left: 0.625rem;
          }
        }
      }
    }
  }

  &.min {
    h1,
    .partner {
      @include bp-desktop-min {
        width: calc(100% - 3.25rem);
        margin-left: 0.875rem;
      }
    }

    .partner {
      @include bp-desktop-min {
        margin-top: 1rem;
      }
    }

    nav {
      @include bp-desktop-min {
        padding: 4.063rem 0;
        margin-top: 0;
      }

      .bg-nav {
        opacity: 0;
      }

      ul {
        @include bp-desktop-min {
          padding: 0 2.375rem 0 0.875rem;
        }

        li {
          @include bp-desktop-min {
            min-height: auto;
            margin: 1.25rem 0;
            justify-content: center;

            a {
              svg {
                margin: 0;
              }

              span {
                display: none;
              }
            }
          }
        }
      }
    }
  }

  .tooltip {
    padding: 0.313rem 1rem;
    @apply text-black-light;
    font-weight: 500;

    &.show {
      opacity: 1;
    }
  }
}
