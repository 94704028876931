@import '../../assets/styles/mixin';

#not-found {
  flex: 1;

  img {
    width: 100%;
    max-width: 37.5rem;
    display: block;
    margin-bottom: 0.938rem;
  }

  h2 {
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 1.3em;
    color: #181b1a;
    margin: 0 0 1.875rem;
    display: block;
  }

  .back {
    border: none;
    background: none;
    outline: none;
    @include trans;
    opacity: 1;

    &:hover {
      opacity: 0.75;
    }

    font-family: 'Poppins';
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 1em;
    color: #181b1a;

    @include bp-tablet-max {
      font-size: 0.938rem;
    }

    .ico {
      width: 3.5rem;
      height: 3.5rem;
      @apply bg-orange;
      border-radius: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 0 0.625rem;

      @include bp-tablet-max {
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 0.75rem;
      }

      svg {
        font-size: 1.563rem;
        color: #f8f8f8;

        @include bp-tablet-max {
          font-size: 1.125rem;
        }
      }
    }
  }
}
