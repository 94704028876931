@import '../../assets/styles/mixin';

html body #profile {
  div.head {
    #user-info {
      width: calc(40% - 0.938rem);

      @include bp-tablet-max {
        width: 100%;
        display: block;
        margin-bottom: 1.875rem;
      }

      .avatar {
        display: block;
        width: 10.625rem;
        height: 10.625rem;

        @include bp-tablet-max {
          width: 7.5rem;
          height: 7.5rem;
          margin: 0 auto;
        }
      }

      .user-gamification {
        margin: 0;
        width: calc(100% - 11.875rem);
        flex: none;

        @include bp-tablet-max {
          width: 100%;
        }

        > h2 {
          display: block;
          font-size: 2.188rem;
          font-weight: 500;
          text-align: left;
          margin: 0;

          @include bp-tablet-max {
            font-size: 1.375rem;
            margin-top: 0.938rem;
          }
        }

        > div {
          display: none;
        }
      }

      .logout {
        display: none;
      }
    }

    .user-stats {
      width: calc(60% - 0.938rem);
      padding-left: 1.875rem;

      @include bp-tablet-max {
        width: 100%;
        padding-left: 0;
      }
    }
  }

  div.dash {
    width: calc(100% + 1.875rem);
    margin: 0 -0.938rem;
    display: block;

    @include bp-tablet-max {
      width: 100%;
      margin: 0;
    }

    @include bp-lg-xl {
      width: 100%;
      margin: 0;
    }

    > div {
      width: calc(50% - 1.875rem);
      margin: 0 0.938rem 1.563rem;

      @include bp-tablet-max {
        width: 100%;
        margin: 0 0 1.25rem;
        float: none;
      }

      @include bp-lg-xl {
        width: 100%;
        margin: 0 0 1.25rem;
        float: none;
      }

      &:nth-child(odd) {
        float: left;

        @include bp-tablet-max {
          float: none;
        }

        @include bp-lg-xl {
          float: none;
        }
      }

      &:nth-child(even) {
        float: right;

        @include bp-tablet-max {
          float: none;
        }

        @include bp-lg-xl {
          float: none;
        }
      }
    }
  }
}
