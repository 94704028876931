@import '../../assets/styles/mixin';

div.quiz-component {
  display: block;
  background: url('../../assets/images/background-purple.jpg') no-repeat center;
  background-size: cover;
  border-radius: 2em;

  @include bp-tablet-max {
    border-radius: 0.875rem;
  }

  .min-h {
    @include bp-tablet-max {
      min-height: 15.625rem;
    }
  }
}
